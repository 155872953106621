import React, { useEffect, useState, useMemo } from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route, useNavigate } from 'react-router-dom';
import { WalletMultiButton, WalletModalProvider } from '@solana/wallet-adapter-react-ui';
import { ConnectionProvider, WalletProvider } from '@solana/wallet-adapter-react';
import { PhantomWalletAdapter } from '@solana/wallet-adapter-wallets';
import { clusterApiUrl } from '@solana/web3.js';
import { useWallet } from '@solana/wallet-adapter-react';
import DiscordCallback from './DiscordCallback';
import './App.css';
require('@solana/wallet-adapter-react-ui/styles.css');

const App = () => {
  const { publicKey, connected } = useWallet();
  const [discordUserId, setDiscordUserId] = useState(null);
  const [jwtToken, setJwtToken] = useState(null);
  const [threadId, setThreadId] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    if (connected) {
      console.log('Connected Wallet Address:', publicKey?.toString());
    }
  }, [connected, publicKey]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const discordId = urlParams.get('discordId');
    const token = urlParams.get('jwtToken');
    const thread = urlParams.get('threadId');

    if (discordId) {
      setDiscordUserId(discordId);
    }
    if (token) {
      setJwtToken(token);
    }
    if (thread) {
      setThreadId(thread);
    }
  }, []);

  const handleVerification = async () => {
    if (!publicKey || !discordUserId || !jwtToken) {
      alert('Please connect wallet and login with Discord before verifying.');
      return;
    }

    try {
      const response = await fetch('https://nftholderverify.com/api/verify-wallet', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${jwtToken}`, // Properly include JWT in Authorization header
        },
        body: JSON.stringify({
          walletAddress: publicKey.toString(),
          discordUserId,
          jwtToken,
          threadId: threadId,
        }),
      });

      if (response.status === 200) {
        alert('Verification successful! Role assigned.');
      } else {
        alert('Verification failed. Please check your NFT ownership or contact support.');
      }
    } catch (error) {
      console.error('Error during verification:', error);
      alert('An error occurred during verification. Please try again.');
    }
  };

  return (
    <>
      <video autoPlay loop muted id="background-video">
        <source src="/1007.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className="App">
        <h1>Connect Your Wallet to Verify NFT Ownership</h1>
        <WalletMultiButton />
        <div className="card-content">
          {connected ? (
            <p>Wallet Address: {publicKey?.toString()}</p>
          ) : (
            <p>No wallet connected</p>
          )}
          {discordUserId ? (
            <>
              <p>Logged in with Discord User ID: {discordUserId}</p>
              <button className="verify-button" onClick={handleVerification}>
                Verify Ownership
              </button>
            </>
          ) : (
            <p>Waiting for Discord verification link...</p>
          )}
        </div>
      </div>
    </>
  );
};

const AppWrapper = () => {
  const network = useMemo(() => clusterApiUrl('mainnet-beta'), []);
  const wallets = useMemo(() => [new PhantomWalletAdapter()], []);

  return (
    <ConnectionProvider endpoint={network}>
      <WalletProvider wallets={wallets} autoConnect>
        <WalletModalProvider>
          <Router>
            <Routes>
              <Route path="/" element={<App />} />
              <Route path="/callback" element={<DiscordCallback />} />
            </Routes>
          </Router>
        </WalletModalProvider>
      </WalletProvider>
    </ConnectionProvider>
  );
};

export default AppWrapper;

