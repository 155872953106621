import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const DiscordCallback = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');

    if (code) {
      // Send the code to your backend to exchange for an access token
      handleDiscordCallback(code);
    }
  }, []);

  const handleDiscordCallback = async (code) => {
    try {
      const response = await fetch('http://localhost:3001/api/auth/discord', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ code }),
      });

      if (!response.ok) {
        throw new Error('Failed to authenticate with Discord');
      }

      const { token, discordUserId } = await response.json();

      // Store JWT token and Discord user ID in local storage or state
      localStorage.setItem('jwtToken', token);
      localStorage.setItem('discordUserId', discordUserId);

      // Redirect to the main page after successful login
      navigate('/');
    } catch (error) {
      console.error('Failed to authenticate with Discord:', error);
    }
  };

  return <div>Loading...</div>;
};

export default DiscordCallback;

